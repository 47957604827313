<template>
  <div class="flex-shrink-8 flex-grow-1 px-sm-0 pb-5">

    <div class="panel errorPanel">
      <div class="container">
        <div class="row">
          <div class="col-md-9 col-lg-6 pt-5 mt-4">
            <section id="main" style="position:relative;">
              <div class="block-404 text-center">
                    <h1 class="large-title text-center">404</h1>
                    <h4 class="sub-title text-center" style="color:white">The page you're looking for could not be found.</h4>
                <a href="#" onclick="window.history.back();" class="btn btn-primary btn-lg">Go Back</a>
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>

  </div>

</template>

<script>
export default {
  name: "ErrorView"
}
</script>